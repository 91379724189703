@import "settings";
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
@import "spinner";
@font-face {
	font-family: 'TitiliumWeb';
	src: url('fonts/TitilliumWeb/TitilliumWeb-Regular.ttf');
	font-weight: normal;
}

@font-face {
	font-family: 'TitiliumWeb';
	src: url('fonts/TitilliumWeb/TitilliumWeb-SemiBold.ttf');
	font-weight: bold;
}

@font-face {
	font-family: 'montserrat';
	src: url('fonts/Montserrat/Montserrat-SemiBold.ttf');
	font-weight: bold;
}

@font-face {
	font-family: 'montserrat';
	src: url('fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
	font-family: 'Fredoka One';
	src: url('fonts/Fredoka_One/FredokaOne-Regular.ttf');
}

html, body, .full-height {
	height: 100%;
}

body {
	font-family: 'TitiliumWeb' !important;
	font-size: 15px;
	font-weight: 500;
	h1 {
		font-size: 32px;
		color: #4C5557;
	}
	h2 {
		font-size: 26px;
	}
	h3 {
		font-size: 22px;
		color: #515151;
		padding-right: 5rem;
		padding-left: 5rem;
	}
	h4 {
		font-size: 20px;

	}
	h5 {
		font-size: 18px;
	}
	p {
		font-size: 20px;
	}
	.text-small {
		font-size: 15px !important;
	}
	.small-footer{
		font-size: 12px!important;
		
	}
	.text-big {
		font-size: 24px;
	}
	.text-medium {
		font-size: 18px;
	}
	button {
		font-size: 26px;
	}
	label {
		font-size: 18px;
	}
	header {
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 99999;
		background-color: #D0F7F5;
		
	}
	#buscando:invalid, #ccaa:invalid {

					color: #90A4AE;
				
			}
	.top_menu_bar_background {
		background-image: URL('../images/fondocabecera.jpg');
		background-repeat: no-repeat;
		background-position: center;
		background-color: #f7f7f7;
	}
	@media (min-width: 576px){
		#exampleModal .modal-dialog {
		    max-width: 900px;
		    margin: 1.75rem auto;
		}
	}
	@media (max-width: 768px) {
		font-size: 13px;
		font-weight: 500;

		.top_menu_bar_background {
			
			background-color: #4d4d4d;
			background-image: none;
		}
		.col-form-label-lg {
			font-size: 13px;
		}
		h1 {
			font-size: 30px;
		}
		h2 {
			font-size: 18px;
		}
		h3 {
			font-size: 20px;
			padding-right: 20px;
			padding-left: 20px;
		}
		h4 {
			font-size: 17px;
		}
		h5 {
			font-size: 15px;
		}
		p {
			font-size: 15px;
			
		}
		.text-big {
			font-size: 18px;
		}
		.text-medium {
			font-size: 14px;
		}
		button {
			font-size: 20px;
		}
		label {
			font-size: 14px;
		}
		.bar-item {
			display: none !important;
			&.active {
				display: flex !important;
			}
		}
		#main-menu-bar {
			&.success {
				display: none;
			}
		}
		.content {
			&.success {
				margin-top: 80px;
			}
		}
	}
}
.redessociales{
	    display: flex;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
}
.slick-list{
	overflow:hidden;
}
.btn-theme {
@media (max-width: 768px) {
	    padding: 10px 20px;
	    max-width: none; 
	}
	background-color: #22CCD5;
	color: #ffffff;
	padding: 10px 40px;
	border-radius: 50px;
	&:focus {
		outline: 0px auto transparent;
	}
	max-width: 40%;
	margin: 10px;
	cursor: pointer;
}

.link {
	margin: 10px;
	align-self: center;
	color: #a4a4a4
}

.theme {
	color: #22CCD5 !important;
	font-weight: 600;
}

.small-text {
	font-size: 13px;
}

.uppercase {
	font-size: 12px;
	text-transform: uppercase;
}

.bold {
	font-weight: 600;
}

:-moz-placeholder {
	color: #90A4AE !important;
}

:-ms-input-placeholder {
	color: #90A4AE !important;
}

::-webkit-input-placeholder {
	color: #90A4AE !important;
}

#top-menu-bar {
	height: 80px;
	max-width: 1150px;
	margin: 0 auto;
	text-align: right;
	.link {
		float: right;
		padding-top: 10px;
		padding-right: 10px;
		font-size: 21px;
		color: #C4C9CC;
		font-weight: 500;
		display: inline-flex;
		align-items: center;
		.theme {
			padding-left: 5px;
		}
	}
	@media (max-width: 768px) {
		img {
			padding-left: 10px;
		}
	}
	.btn_whatsapp{
		position: absolute;
		top: -5px;
		right: 13%;
		width: 50px;
		height: 50px;
		cursor: pointer;
		@media (max-width: 1300px) {
			right: 12%;
		}
		@media (max-width: 953px) {
			right: 120px;
		}
		@media (max-width: 550px) {
			right: 95px;
		}
		
	}
	span{
		&.login-phone{
			position: absolute;
			    right: 30%;
			    width: 170px;
			    top: 2px;
			    font-size: 20px;
			    font-weight: 700;
			    color: #22CCD5;
			@media (min-width: 954px) {
				right: 20%;
			}
			@media (min-width: 1300px) {
				right: 20%;
			}
		}
		&.login-phone3 {
			    position: absolute;
			    right: 30%;
			    width: 170px;
			    top: 46px!important;
			    font-size: 20px;
			    font-weight: 700;
			    color: #22CCD5;
			    @media (min-width: 954px) {
				right: 20%;
			}
			@media (min-width: 1300px) {
				right: 20%;
			}
			}
		
		&.login-phone2 {
		    position: absolute;
		    right: 30%;
		    width: 170px;
		    top: 24px!important;
		    font-size: 14px!important;
		    font-weight: 700;
		    color: #22CCD5;
		    @media (min-width: 954px) {
				right: 20%;
			}
			@media (min-width: 1300px) {
				right: 20%;
			}
		}
	}
	img {
		float: left;
		padding-top: 20px;
		margin-left: 5px;
		&.free-image {
			position: absolute;
			right: 3%;
			width: 70px;
			top: -6px;
			@media (max-width: 953px) {
				display:none
			}
			@media (min-width: 954px) {
				right: 10%;
			}
			@media (min-width: 1300px) {
				right: 20%;
			}
		}
		&.login-image {
			position: absolute;
			right: 3%;
			width: 70px;
			top: -15px;
			@media (min-width: 954px) {
				right: 2%;
			}
			@media (min-width: 1300px) {
				right: 5%;
			}
		}

	}
}

#main-menu-bar {
	height: 60px;
	border-top: 1px rgba(211, 216, 219, 0.3) solid;
	border-bottom: 1px rgba(211, 216, 219, 0.3) solid;
	background-color: #4D4D4D;
	&.confirm {
		display: none;
	}
	.bar {
		max-width: 1150px;
		display: flex;
		height: 100%;
		margin: auto;
		.bar-item {
			height: 100%;
			vertical-align: middle;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			border-left: 1px rgba(211, 216, 219, 0.3) solid;
			color: #C4C9CC;
			text-decoration: none;
			font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;
			@media (max-width: 768px) {
				.custom-control {
					display: none;
				}
			}
			.custom-control {
				padding-left: 2.0rem;
				.custom-control-indicator {
					position: absolute;
					top: 6px;
					left: -1px;
					display: block;
					border-radius: 50px;
					width: 20px;
					height: 20px;
					border: 2px solid #C4C9CC;
				}
			}
			&.completed {
				background-color: #4D4D4D;
				.custom-control .custom-control-indicator {
					border-color: #5DC49B;
					color: #5DC49B;
					text-align: center;
					&::after {
						content: "✓";
						bottom: -8px;
						left: 1px;
						position: absolute;
						font-size: 26px;
					}
				}
			}
			&.active {
				background-color: #22CCD5;
				color: #ffffff;
				.custom-control .custom-control-indicator {
					border-color: #ffffff !important;
					color: #ffffff !important;
				}
			}
			&:last-child {
				border-right: 1px rgba(211, 216, 219, 0.3) solid;
			}
		}
	}
}

.content {
	max-width: 1150px;
	padding: 0px 20px;
	text-align: center;
	color: #4C5557;
	margin: 140px auto 20px auto;
	@media (max-width: 991px) {
		padding: 0px;
		
	}
	&.confirm {
		margin-top: 80px;
	}
	.title {
		padding-top: 40px;
		h1 {
			display: inline-flex;
		}
		.btn-change-product {
			    justify-content: center;
			    align-items: center;
			    border: 3px solid #E9ECEE;
			    border-radius: 50px;
			    font-size: 15px;
			    background-color: #fff;
			    padding: 8px 20px;
			    color: #90A4AE;
			    font-weight: 600;
			    display: inline-flex;
			    margin-left: 10px;
			    vertical-align: super;
		}
	}
	hr {
	//	width: 100%;
	//	margin-top: 40px;
	//	margin-bottom: 40px;
	//	border-top: 1px rgba(211, 216, 219, 0.5) solid;
	}
	.row {
		margin-left: -5px;
		margin-right: -5px;
	}
	@media (max-width: 768px) {
		.title {
			padding-top: 30px;
		}
		hr {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}
	&.success {
		max-width: initial;
		padding: 0;
		h2 {
			color: #607D8B;
			padding-bottom: 20px;
		}
		h3 {
			color: #37474F;
			padding-bottom: 40px;
			padding-left: 5px;
			padding-right: 5px;
		}
		p {
			color: #607D8B;
			&.error-message {
				display: block;
				font-size: 13px;
				line-height: 18px;
				color: #dc3545 !important;
				padding-top: 5px;
				width: 100%;
			}
		}
		.subtitle {
			max-width: 1150px;
			margin: auto;
			padding-bottom: 20px;
			.go-icon {
				padding-top: 60px;
				float: left;
				padding: 60px 30px;
				margin-left: 10px;
				&.go-icon-mayor-95 {
					width: 300px;
					text-align: center;
					p {
						color: red;
						&.porcentaje {
							position: absolute;
							top: 25px;
							left: 90px;
							font-size: 26px;
						}
					}
				}
			}
			.resumen {
				float: left;
				text-align: left;
				padding-left: 10px;
				color: white;

				.operaciones {
					width: 100%;
					float: left;
					background: #4d4d4d;
					padding-top: 15px;
					margin-bottom: 20px;
				}
				.valor {
					text-align: center;
					background: white;
					float: left;
					width: 40%;
					color: black;
					margin-bottom: 8px;
				}
				.etiqueta {
					float: left;
					width: 60%;
					text-align: left;
					padding-left: 10px;
				}
				.recalcular {
					display: inline-block;
					width: 100%;
					margin: 20px auto;
					position: relative;
					text-align: center;
					a.boton_recalcular {
						color: white;
						border: solid 1px;
						border-radius: 15px;
						padding: 5px 15px;
					}
					a:hover {
						text-decoration: none;
					}
				}
			}
			.descripcion {
				@media (min-width: 768px) {
					padding-top: 100px;
					padding-left: 40px;
				}
				text-align: left;
				float: left;
				.description-button {
					text-align: center;
					@media (min-width: 768px) {
						margin-top: 80px;
					}
				}
				.btn-theme {
					background-color: #22CCD5;
					color: #ffffff;
					padding: 10px 40px;
					border-radius: 50px;
					max-width: 100%;
					margin: 10px;
					cursor: pointer;
				}
				.btn-theme > svg {
					position: relative;
					top: 3px;
				}
				b {
					color: #22CCD5;
				}
				label {
					font-size: 16px;
				}
			}
		}
		.title {
			background-color: #22CCD5;
			color: #ffffff;
			margin-bottom: 0px;
			padding: 0;
			.row {
				min-height: 90px;
				align-items: center;
			}
			h1 {
				color: #FFFFFF;
			}
			h3 {
				color: #FFFFFF;
				padding-bottom: 5px;
			}
			.title_ico {
				margin-left: 10px;
				width: 5%;
				float: left;
			}
		}
		@media (max-width: 768px) {
			.title {
				padding: 0px 0px;
				margin-bottom: 50px;
				background-color: #22CCD5;
			}
			.subtitle {
				padding: 0 20px;
			}
		}
		.row {
			max-width: 1150px;
			margin: auto;
		}
		.precio {
			background-color: white;
			position: relative;
			color: black;
			padding: 5px;
			width: 15%;
			float: left;
			
			justify-content: center;
			h1 {
				font-size: 28px;
				color: black;
			}
		}
		.textos {
			float: left;
			h2 {
				color: #FFFFFF;
				padding-bottom: 0px;
				margin-bottom: 0;
				padding-left: 5px;
				padding-right: 5px;
			}
			p {
				color: #FFFFFF;
				padding-bottom: 0px;
				margin-bottom: 0;
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
}

form {
	&.narrow {
		max-width: 640px;
		margin: auto;
	}
	label {
		font-weight: 600;
		font-size: 15px;
		color: #333333;
		&.col-form-label-lg {
			font-size: 15px;
		}
		&.col-form-label {
			color: #333333;
			line-height: 16px;
			span {
				display: block;
				padding-top: 5px;
				color: #90A4AE;
				line-height: 18px;
			}
		}
		.text-muted{
			color:#545454!important;
		}
	}
	button {
		background-color: #22CCD5;
		color: #ffffff;
		padding: 10px 40px;
		border-radius: 50px;
		cursor: pointer;
		border:none;
		&:focus {
			outline: 0px auto transparent;
		}
	}
	.form-group {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 1.5rem;
		label {
			text-align: left;
		}
		.form-control {
			//color: #37474F;
			//background-color: #FFF;
			//border: 1px solid #D3D8DB;
			//height: 40px;
			border-radius: 0px;
			&select {
				height: 54px !important;
				-webkit-appearance: none;
				color: #37474F;
				&.ng-pristine {
					color: #90A4AE;
				}

			}
		}
		.error-message {
			display: none;
		}
	}
	@media (max-width: 768px) {
		.form-group {
			margin-bottom: 10px;
			&.last {
				margin-bottom: 40px;
			}
		}
	}
	.form-text {
		text-align: left;
		color: #90A4AE;
		font-size: 13px;
		line-height: 18px;
	}
	&.submitted {
		.form-group {
			.error-message {
				display: block;
				font-size: 13px;
				line-height: 18px;
				color: #dc3545 !important;
				text-align: left;
				padding-top: 5px;
				width: 100%;
			}
			&.error {
				.form-control, .custom-control-indicator {
					border: 1px #dc3545 solid !important;
				}
			}
		}
	}
}

.custom-control-indicator {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	border-radius: 10px;
	padding: 0.375rem 0.75rem;
}
.bar-item .custom-control-indicator{
	padding: 10px;
}

.custom-control-description {

	a {
		color: #22CCD5;
	}
}

.custom-checkbox {
	cursor: pointer;
	.custom-control-indicator {
		background-color: #FFF;
		text-align: center;
		width: 2rem;
		height: 2rem;
		border: 1px solid #D3D8DB;
		pointer-events: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	&.custom-checkbox-small {
		.custom-control-description {
			padding-left: 5px;
		}
		.custom-control-indicator {
			width: 25px;
			height: 25px;
		}
		.custom-control-input:checked ~ .custom-control-indicator {
			&:after {
				top: -10px;
				left: 1px;
			}
		}
	}
	.custom-control-input:checked ~ .custom-control-indicator {
		color: #fff;
		background-color: #22CCD5;
		&:after {
			content: "✓";
			font-size: 30px;
			position: absolute;
			top: 2px;
			left: 2px;
		}
	}
	.custom-control-description {
		float: left;
		color: #90A4AE;
		padding-left: 1rem;
		padding-top: 5px;
	}
}

.row-radio {
	width: 100%;
	margin: auto !important;
	padding-top: 40px;
	label {
		text-align: center;
	}
	@media (max-width: 576px) {
		padding-left: 0px;
		padding-right: 0px;
	}
	.custom-radio {
		margin-bottom: 20px;
		padding-left: 15px;
		cursor: pointer;
		@media (max-width: 576px) {
			margin-bottom: 10px;
			padding-left: 5px;
			padding-right: 5px;
			/*max-width: 160px;*/
		}
		.custom-control-indicator {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 3px solid #E9ECEE;
			border-radius: 50px;
			&::after {
				content: " ";
				/*padding-bottom: 100%;*/
			}
		}
		.custom-control-description {
			color: #90A4AE;
			line-height: 20px;
			text-align: center;

			svg {
				display: block;
				/*margin: auto auto 20px auto;*/
				margin-right: 10px;
			}
			.home-type-title {
				font-size: 12px;
			}
		}
		.custom-control-input:checked ~ .custom-control-indicator {
			border: 2.5px solid #22CCD5;
			.custom-control-description {
				color: #37474F;
				.color {
					stroke: #22CCD5;
				}
			}
		}
	}
}

.row-radio-classic {
	padding-top: 40px;
	@media (max-width: 798px) {
		&.form-group {
			margin-bottom: 0;
		}
	}
	label {
		margin-bottom: 2.5rem;
		&.col-form-label {
			margin: 0;
		}
	}
	.custom-radio {
		.custom-control-indicator {
			width: 30px;
			height: 30px;
			border-radius: 100%;
			border: 1px rgba(211, 216, 219, 1) solid;
			pointer-events: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.custom-control-description {
			float: left;
			padding-left: 1rem;
			padding-top: 5px;
			color: #90A4AE;
			line-height: 20px;
		}
		.custom-control-input:checked ~ .custom-control-indicator {
			border: 6px #ffffff double;
			background-color: #22CCD5;
			width: 35px;
			height: 35px;
			left: -2px;
		}

	}
}


@media (min-height: 700px) {
	footer {
		position: relative;
	}
	.content {
		margin-bottom: 100px;
	}
	.content_private {
		margin-bottom: 200px;
	}
}

footer {
	bottom: 0px;
	width: 100%;
	background-color: #222;
	padding-top: 2%;
	padding-bottom: 2%;
	text-align: center;
	.row {
		max-width: 1080px;
		margin: auto;
		.image {
			height: 60px;
			
			@media (max-width: 768px) {
				max-width: 50%;
				height:auto;
			}
		}
	}
	a{
		color: #22CCD5;
	}
}

.flex-container {
	height: 100%;
	padding: 0 20px;
	margin: 0;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	align-items: center;
	justify-content: center;
	.flex-column {
		width: auto;
		.flex-item {
			padding: 5px;
			margin: 10px;
			text-align: center;
			button {
				margin: 30px 0;
				width: 100%;
			}
		}
	}
}

/*css tabla de la lista de usuarios*/

.input_search {
	color: #37474F;
	background-color: #F5F8FA;
	border: 1px solid #D3D8DB;
	line-height: 2.5;
	border-radius: 50px;
}

.view_user {
	padding-top: 40px;
}

.user_left {
	width: 50%;
	float: left;
	text-align: left;
}

.user_right {
	width: 50%;
	float: right;
	text-align: right;
}

.list-user {
	text-align: left;
	td, th {
		padding: 10px;
	}
	tr:nth-child(even) {
		background-color: #F2F2F2;
	}
	th span {
		cursor: pointer;
	}
}
/*cabecera2 login*/
.enlaces_head.row {
	@media (max-width: 768px) {
	font-size:20px;
	padding: 15px;
	}

    text-align: center;
    font-size: 50px;
    font-weight: 700;
    padding-bottom: 10px;
    background-color:#ffffff;

    a{
    color: #848484;
    &.active {
    color:black;
    }

    }
}
   .content_private{
    max-width: 1150px;
    padding: 0px 20px;
    text-align: center;
    color: #4C5557;
    margin: 230px auto 20px auto;
    @media (min-height: 900px) {
    	margin: 230px auto 200px auto;
    }
}
.private_rigth{
	text-align: right;
}
.private_left{
	text-align: left;
}
.titulo_document{
	font-size: 16px;
    width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 62px;
    font-weight: bold;
}
.files-container {
	position: absolute;
	width: 100%;
	bottom: 10px;
	left: 0px;
}

select.form-control:not([size]):not([multiple]) {
    height: 40px;
    -webkit-appearance: none;
	cursor: pointer;
    
}
button {
	cursor: pointer;
}
button a:hover{
	    color: #ffffff;
    text-decoration: none;
}
.drop-box {
        background: #FFF;
        border: 5px dashed #DDD;
        width: 300px;
        min-height: 116px;
        text-align: center;
        padding-top: 25px;
        margin: auto;
        cursor:pointer;
        font-size: 10px;
	&.completed {
		border-color: #93DFB8;
	}
	&.rejected {
		border-color: #A21A0A;
	}
	p{
		font-weight: 600;
		font-size: 14px;
		margin-bottom: 5px;
	}
}

.dragover {
        border: 5px dashed #22CCD5

;
}
.list_bank {
    color: #4D4D4D;
    background-color: #ffffff;
    position: relative;
    margin: auto;
    a:not([href]):not([tabindex]){
    color:white;
    }
}
.list_bank th {
    font-weight: 700;
}
.list_bank tr th, .list_bank tr td {
    padding: 20px 20px 20px 20px;
}
tr:nth-child(even) {
    background: #ffffff;
}

a.btn_banco:hover {
    color: white;
    text-decoration: none;
}
a.btn-theme.btn_banco.banco_rechazado:hover {
    cursor: default;
}
/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {
    display: none;
}

/* Removes the spin button */
input[type="date"]::-webkit-inner-spin-button { 
    display: none;
}

.form_success_login  {
	input{
		background-color: #FFFFFF;
	    background-repeat: no-repeat;
	    background-size: 20px;
	    background-position: left;
	    padding-left: 36px;
	    background-position-x: 10px;
	    border-radius: 50px;
	    height: 40px;
    }
    form .form-group .form-control {
	    color: #333333;
	    background-color: #FFFFFF;
	    border: 1px solid #000000;
	    line-height: normal;
	    border-radius: 50px;
	    width: 80%;
	    margin: auto;
	    
	}
	form.submitted .form-group .error-message{
		width: 80%;
   		margin: auto;
	}
	.alert-danger {
        width: 80%;
	}
}
.btn_confirm{
    width: 80%;
    margin: auto;
    font-size: 15px;
    border-style: none;
    height: 45px;
}
@media (max-width: 768px){
.content.success.confirm .title {
    padding: 0; 
    margin-bottom: 10px;
    background-color: #22CCD5;
}
.imagen_boton{
	display:none;
}
.movil_enlaces_head{
	display:none;
}
.content_private.content_private_result{
    
    margin: 155px auto 20px auto;
}

}
.scoring_alert{
	    position: relative;
    padding: 0.75rem 1.25rem;
    /* margin-bottom: 1rem; */
    /* border: 1px solid transparent; */
    border-radius: 0.25rem;
    text-align: center;
        color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.tabla_bancos dl {
    border-bottom: 2px outset;
    padding-bottom: 20px;
}
a.delete_file {
    position: absolute;
    top: -12px;
    left: -5px;
}
.delete_files {
    position: relative;
    min-width: 150px;
    
 
}
.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
	&.scoring {
		position: fixed;
		top: 0;
		width: 100%;
	}
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}
.btn-trans{
	background: none;
    border: none;
    color: #22ccd5;
    outline:none;
    
}


/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    perspective: 1500px;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #22ccd5;
    color: #fff;
    transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    transform-origin: bottom left;
	.sticky-top {
		top: 80px;
	}
	.components {
		padding: 20px 0;
		border-bottom: 1px solid #fff;
	}
	li {
		display: flex;
		height: auto;
		padding: 20px 0;
		-webkit-transition: background-color 2s ease-out;
		-moz-transition:background-color 2s ease-out;
		-o-transition: background-color 2s ease-out;
		-ms-transition:background-color 2s ease-out;
		transition: background-color 2s ease-out;
		opacity: 0.5;
		&:hover {
    		background:#333;
		}
		&.active {
    		background:#333;
			opacity: 1;
		}
		&.completed {
			opacity: 1;
			.sidebar-icons {
				span {
					background-color: #fff;
					border-color:#fff;
					color: #22ccd5;
					font-size: 16px;
					padding: 3px 7px 3px 7px;
					display: none;
					&.check {
						display: inline-block;
					}
				}
			}
		}
		&.pending {
			opacity: 1;
			.sidebar-icons {
				span {
					background-color: #fff;
					border-color:#fff;
					color: #DA7A59;
					font-size: 18px;
					padding: 0 5px;
					display: none;
					&.pending {
						display: inline-block;
					}
				}
			}
		}
	}
	.sidebar-icons {
		width: 25%;
    	float: left;
    	hr{
    		width: 15px;
    		height: 2px;
    		float: left;
    		position: relative;
    		background: #fff;
    		margin-top: 13px;
    		vertical-align: middle;
    	}
    	span{
    		background: #22ccd5;
    		border: solid;
    		border-radius: 50%;
    		padding: 2px 10px 2px 10px;
			display: inline-block;
			&.check, &.pending {
				display: none;
			}
    	}
	}
	.sidebar-text{
		width: 74%;
    	float: left;
		font-size: 14px;
		font-family:"Montserrat";
		display: block;
		color: #fff;
		font-weight: bold;
    	p{
    		font-size: 12px;
    		line-height: 2px;
			color: #fff;
			padding: 10px 0;
			margin: 0;
    	}
	}



}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article, a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}



/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
    width: 100%;
    padding: 0px;
    //min-height: 100vh;
    transition: all 0.3s;
    padding-bottom: 0px;
}
@media (max-width: 991px) {
	#content {
		margin-top: 70px;
	}
}

#sidebarCollapse {
    width: 40px;
    height: 40px;
    background: #f5f5f5;
    cursor: pointer;
}

#sidebarCollapse span {
    width: 80%;
    height: 2px;
    margin: 0 auto;
    display: block;
    background: #555;
    transition: all 0.8s cubic-bezier(0.810, -0.330, 0.345, 1.375);
    transition-delay: 0.2s;
}

#sidebarCollapse span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
}
#sidebarCollapse span:nth-of-type(2) {
    opacity: 0;
}
#sidebarCollapse span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
}


#sidebarCollapse.active span {
    transform: none;
    opacity: 1;
    margin: 5px auto;
}


/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 991px) {
    #sidebar {
        margin-left: -250px;
        transform: rotateY(90deg);
		.sticky-top {
			top: 0;
		}
    }
    #sidebar .sidebar-header {
    padding: 20px;
    background: #fff;
}
    #sidebar.active {
        margin-left: 0;
        transform: none;
    }
    #sidebarCollapse span:first-of-type,
    #sidebarCollapse span:nth-of-type(2),
    #sidebarCollapse span:last-of-type {
        transform: none;
        opacity: 1;
        margin: 5px auto;
    }
    #sidebarCollapse.active span {
        margin: 0 auto;
    }
    #sidebarCollapse.active span:first-of-type {
        transform: rotate(45deg) translate(2px, 2px);
    }
    #sidebarCollapse.active span:nth-of-type(2) {
        opacity: 0;
    }
    #sidebarCollapse.active span:last-of-type {
        transform: rotate(-45deg) translate(1px, -1px);
    }

}
.wrapper.private{
	margin-top: 80px;
}
.wrapper.private, .content{
	font-family:"Montserrat";
	font-size: 16px;
	line-height: 1.2em;
	color:#000000;
	
	.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none!important;
}
	p{
		font-size: 16px;
	}
	h1{
		font-family: montserrat;
    font-size: 30px;
    font-weight: 600;
    color: #22ccd5;
    line-height: 1.3em;
		
	}
	
	h2{
		font-family: 'Fredoka One';
		font-size: 28px;
		color:#333333;
	}
	.title.text-left h2{
		font-size: 23px;
	}
	h3{
		font-family:"Montserrat";
		font-size: 24px;
		color:#333333;
		font-weight: bold;
		padding-right: 0;
    	padding-left:0;
	}
	h4{
		font-size: 20px;
   		font-weight: bold;
    	color:#333333;

	}
	.caja h4{
			font-size: 16px;
		}
	.valores{
		h1{
			font-family: 'Montserrat';
			font-size: 40px;
			color:#262626 !important;
			font-weight: bold;
			}
		h4{
			font-size: 16px
			}
		span{
			font-size: 12px;
		}
	}
	.vinculante{
		h4{
			font-size: 28px;

		}}
	.small-text{
		font-size: 14px;
		p{
			font-size: 14px;
		}
	}
	.mini-text{
		font-size: 12px;
		font-weight: 100;

		p{
			font-size: 12px;
		}
	}


}

.resumen{
	color: #333;
	tr:nth-child(even) {
		background: none!important;
	}
	.menu-collapse{
		background: #333;
   		padding: 10px;
   		button{
   			background: #333;
		    border: none;
		    color: #fff;
		    font-size: 16px;
		    font-family: 'Montserrat';
		    font-weight: bold;
		    :focus{
		    	outline: none;
		    }
		    
   		}
   		.collapse-icons{
		    	float: right;
    			font-size: 24px;
    			color: #fff;
		    }
	}
	#collapseResumen{
		background-color: #e9e9e9;
		.card-body{
			background-color: #e9e9e9;
			flex: 1 1 auto;
    		padding: 1.25rem;
		}
		.card{
			border-top: solid 2px white;
		}
	}
	.progress-left{
		width: 35%;
	    float: left;
	    text-align: right;
	    padding-right: 12px;
	    overflow:hidden;
   		white-space:nowrap;
    	text-overflow: ellipsis;
	}
	.progress-center{
		width: 40%;
	    float: left;
	    margin-top: 5px;
	}
	.progress-right{
		width: 25%;
	    float: left;
	    text-align: right;
	}
	.progress-left-rest{
		width: 25%;
	    float: left;
	    text-align: right;
	    padding-right: 12px;
	}
	.progress-center-rest{
		width: 8%;
	    float: left;
	    margin-top: 5px;
	    margin-right: 2%;
	}
	.progress-right-rest{
		width: 65%;
	    float: left;
	    text-align: left;
	}
	.progress-bar.progress-bar-danger{
		background: #A21A0A!important;
	}
	.progress-bar.progress-bar-ingreso{
		background: #7ebf9d!important;
	}
}
hr.separate{
	    position: relative;
	    left: 0px;
	    text-align: left;
	    border-top: solid 2px;
	    margin-right: 80%;
	}

.financiacion{
	padding: 50px 8% 50px;
    border: solid 2px #e9e9e9;
    border-top: solid 1px #e9e9e9;
	
	span{
		text-align: right;
		float: right; 
		font-size:22px; 
		font-weight: 600;
	}
}
 
.seccion::before,
.seccion::after {
	position: absolute;
	content: '';
	pointer-events: none;
}

/* Triangles */
.ss-style-triangles::before,
.ss-style-triangles::after {
	left: 50%;
	width: 50px;
	height: 50px;
	-webkit-transform: translateX(-50%) rotate(45deg);
	transform: translateX(-50%) rotate(45deg);
}



.ss-style-triangles::after {
	bottom: -25px;
	z-index: 10;
	background: inherit;
}

.seccion {
	position: relative;
	
	background: #fff;
	
}


/*btn private*/
.btn-theme-private{
	background-color: #22CCD5;
    color: #f5f5f5;
    padding: 16px 40px;
    cursor: pointer;
    font-family: montserrat;
    font-size: 24px;
    font-weight: bold;
    border-radius: 5px;
    
    border:none;
    &.prescoring{
    	border-radius: 50px;
    	
    }
    

}
.btn-theme-private:hover{
	background-color: #333333;
    	color: #ffffff;
    	text-decoration: none;
}

/*tabla titulares*/

.numero-titulares table th, .numero-titulares table td{
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
    font-size: 14px;
}
/*Caja  bancos*/
.caja-resumen-banco>div:nth-child(even) {
    background: rgba(34,204,213,0.1);
}

.caja-resumen-banco div {
    padding: 4px;
}
.caja-requisitos-banco p {
    margin-bottom: 0px;
    /* padding-bottom: 0px; */
}
#content-private, .financiacion, .content, .flex-container, .modal-body{
	select, input {
	 //    border-top: none;
	 //    border-left: none;
	 //    border-right: none;
	 //    background-color: #fff;
	 //    line-height: normal;
		// border-bottom: 1px solid;
		    border: 3px solid #E9ECEE;
		    border-radius: 50px!important;
		    background-color: #fff;
		    line-height: normal;
		&[disabled] {
			color: rgb(127, 127, 127);
		}
	}
	select:not([disabled]), input:not([disabled]) {
		//border-bottom: solid #22ccd5;
	}

	select {
		-webkit-appearance: none;
		border-radius: 0;
	}
	select:focus{
		outline: none!important;
	}
	input:focus{
		outline: none!important;
		box-shadow: none;
	}
	input[type="checkbox"]{
		width: auto;
	}
}

.infomessage-success{
    border: solid #93dfb8;
    padding: 5%;
    text-align: center;
    margin-bottom: 10px;
    h2{
     color: #93dfb8 !important;   
    }
    button{
        background-color:#93dfb8;
    }
}
.infomessage-warning{
    border: solid #da7a59;
    padding: 5%;
    text-align: center;
    margin-bottom: 10px;
    h2{
     color: #da7a59 !important;   
    }
    button{
        background-color:#da7a59;

    }
}
.oferta-warning{
	color: #da7a59 !important; 
	border: solid #da7a59;
	p,h1,h2{
		color: #da7a59 !important; 
	}
}
.oferta-danger{
	color: #a21a0a !important;
	p,h1,h2{
		color: #a21a0a !important;
	}
}
.infomessage-danger{
    border: solid #a21a0a;
    padding: 5%;
    text-align: center;
    margin-bottom: 10px;
    h2{
     color: #a21a0a !important;   
    }
    button{
        background-color:#a21a0a;
    }
}
.cabecera_private{
	background-image: url('../images/Main-banner-img.jpg');
    background-size: cover;
    background-position: top;
    min-height: 250px;
    

}
.cabecera_private7{
	background-image: url('../images/firma.jpg');
    background-size: cover;
    background-position: top;
    min-height: 250px;
    

}
.icono-private7 img{
	background: #fff;
    margin-top: -69px;

}
.bocadillo{
    	background: #fff;
    padding: 25px 10px 25px 25px;
    max-width: 550px;border-radius: 30px; border: solid 0px;
    box-shadow: 1px 1px 12px -2px;;
    }
.bocadillo-movil{
	padding: 7%; margin-top: -82px;
}
.separate-full{
	border-top: 3px solid #333; margin-top: 5px; margin-bottom: 5px;  
}
.bg-keys-private1{
	background-image: url('../images/keys-img.png');
    background-size: cover;
    min-height: 410px;
    padding-bottom:200px;
    background-position: center;
}
.caja-formularios-private1{
	     border: solid #A4A4A4 1px;    
	     box-shadow: 1px 1px 1px #A4A4A4;    
	     margin-top: -200px;    
	     background: #fff;
}
@media (max-width: 991px){
	html {
		overflow: hidden;
	}
	#sidebar, #content, .flex-container {
		overflow: auto;
		>.flex-column {
			height: 100%;
		}
	}
	.prescoring-step-view {
		overflow: auto;
		height: 100%;
	}
	#content-private {
		width: 100vw;
	}
	.wrapper.private{
		margin-top: 0px;
		height: 100%;
	}
	.wrapper.private, .content{
		font-family:"Montserrat";
		font-size: 16px;
		color:#000000;
		
		p{
			font-size: 16px;
		}
		h1, .valores h1 {
			font-family: 'Fredoka One';
			font-size: 32px;
			color:#22ccd5;
		}
		h2{
			font-family: 'Fredoka One';
			font-size: 24px;
			color:#333333;
		}
		h3{
			font-family:"Montserrat";
			font-size: 20px;
			color:#333333;
		}
		.small-text{
			font-size: 14px;
		}
		.btn-theme-private{
			background-color: #22CCD5;
		    color: #f5f5f5;
		    padding: 10px 20px;
		    cursor: pointer;
		    font-family: montserrat;
		    font-size: 14px;
		    font-weight: bold;
		    border-radius: 5px;
		    border:none;
		    &.prescoring{
    			border-radius: 50px;
    	
  			  }
		}
		.btn-theme-private:hover{
			background-color: #333333;
	    	color: #ffffff;
	    	text-decoration: none;
		}
		.resumen{
			#collapseResumen{
				background-color: #e9e9e9;
				.card-body{
					background-color: #e9e9e9;
					flex: 1 1 auto;
    				padding: 1.25rem;
				}
				.card{
					border-top: solid 2px white;
					border-right: none;
					border-left: none;
					border-bottom: none;
				}
			}
		}
	}
}
.interes_variable {
    background: #333;
    color: #fff;
    padding: 10px;
    margin-bottom: 3px;
    h1{
    	color:#fff!important;
    }
    h4{
    	color:#fff!important;
    }
}
.condiciones_banco.interes_variable {
	height: 100%;
}

.password-form {
  max-width: 500px;
  margin: auto;
  text-align: center;
}

@media (max-width: 991px) {
	#offers {
		.arrow {
			color: #22CCD5;
			font-size: 40px;
			height: 60px;
			margin-top: 200px;
			width: 60px;
			background: transparent;
			border: none;
			&.hidden {
				visibility: hidden;
			}
			&.arrow-prev {
			}
			&.arrow-next {
			}
		}
	}
}

.corporativo{
	color:#22ccd5;
}
.resumen-success p{
	font-size: 16px;
}
table.ofertas_prescoring {
	border: 1px solid;
	th {
		border: 1px solid;
		padding: 15px;
	}
	tr {
		&.bg-theme-light {
			background-color: #D0F7F5;
		}
	}
	td {
		border: 1px solid;
		padding: 10px;
		.btn {
			font-size: 15px;
			border: 1px solid #22CCD5;
			border-radius: 50px;
			color: #22CCD5;
			padding: 3px 20px;;
			background-color: #fff;
		}
	}
	@media (max-width: 953px) {
		th, td {
			padding: 10px;
			.btn {
				font-size: 14px;
			}
		}
	}
}
dl.ofertas_prescoring{
	.btn {
		font-size: 15px;
		border: 1px solid #22CCD5;
		border-radius: 50px;
		color: #22CCD5;
		padding: 3px 20px;;
		background-color: #fff;
		
		@media (max-width: 953px) {
			font-size: 14px;
		}
	}
	dt {
		width: 140px;
		text-align: right;
		&.full-width {
			padding-top: 10px;
			width: 100%;
		}
	}
	dd {
		max-width: calc(100% - 140px);
		text-align: left;
		.broker {
			padding: 0;
			margin: -3px 0 0;

		}
	}
}
.modal {
	z-index: 9999999;
	.modal-content {
		margin-top: 25%;
		border: 3px solid #22CCD5;
		.modal-header {
			border: none;
			justify-content: center;
			padding: 60px 0 20px;
			.close {
				position: absolute;
				top: 10px;
				right: 10px;
				border: 2px solid #22CCD5;
				border-radius: 30px;
				padding: 0;
				margin: 0;
				width: 35px;
				font-size: 30px;
				color: #22CCD5;
			}
		}
	}
}
.modal-backdrop {
	z-index: 999999;
}
select:not([disabled]){
    background-image: url(../images/arrow-down.png);
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: center;
    &:focus{
		outline: none!important;
		box-shadow: none!important;

	}
	
 }

.fa-angle-down, .fa-angle-up{
	display:none;
}
button:focus {
	outline: none;
}
button[aria-expanded="false"] .fa-angle-down{
	display: inline;
}
button[aria-expanded="true"] .fa-angle-up{
	display: inline;
}
.mobile-dl {
  display: block;
  width: 100%;
  height: 100%;
  dt, dd {
    float: left;
  }
  dt {
    clear: both;
    padding-right: 10px;
  }
}

.form-group {
	label {
		.helper {
			border: 1px solid #22ccd5;
			color: #22ccd5;
			padding: 1px 1px;
			border-radius: 100%;
			width: 18px;
			margin-left: 10px;
		}
	}
	.cut-select {
		padding-right: 36px;
	}
}

.loading {
	z-index: 99999999;
}
/*Modal confirm*/
div[uib-modal-window="modal-window"] {
    opacity: 1!important;
}
div[uib-modal-backdrop="modal-backdrop"]{
	opacity: 0.5!important;
}
.modal .modal-content {
    margin-top: 40%;
    border: 3px solid #22CCD5;
}
h5.modal-title {
    font-size: 34px;
}

.btn-danger {
	 color: #fff;
	 background-color: #dc3545 !important;
	 border-color: #dc3545 !important;
}
.btn-outline {
	color: #000000;
	background-color: transparent !important;
	border-color: transparent !important;
}
.improve-p {
	margin-bottom: 0px;
	line-height: 23px;
	.goto-extension-p {
		cursor: pointer;
		font-size: 16px;
	}
}
.inside-label {
	color: #333333 !important;
	line-height: inherit !important;
}
.file-upload-label {
  &.required {
    &:after {
      content: "*";
      color: #dc3545;
      padding-left: 2px;
    }
  }
}

@keyframes fadein
{
  0%{opacity: 0}
  100%{opacity:1}
}

.animate_fadein_default {
	opacity: 0;
	animation-name: fadein;
	animation-duration: 1s;
	animation-fill-mode:forwards;
}
#exampleModal{
	.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
    position: fixed;
    bottom: 0px;
    right: 30px;
    width: 90%;
    background: #fff!important;
	}
	.modal-content {
    max-height: 510px;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}
}